<style lang="scss" scoped>
.capacity-data-panel {
    position: absolute;
    right: 20px;
    bottom: 150px;

    .reference_button {
        width: 35px;
        height: 90px;
        white-space: pre-wrap;
        padding: 10px;
        line-height: 1.3em;
        background-color: #fff;
        border: 1px solid rgba(229, 229, 229, 1);
        color: #1989FA;
        font-size: 14px;
        font-weight: 400;
    }
}

/deep/ {
    .el-input__inner {
        border: none;
    }
}

.num {
    font-size: 20px;
    text-align: center;
}

.color-1989FA {
    color: #1989FA;
}

.color-FF3B30 {
    color: #FF3B30;
}

</style>
<style lang="scss">
.capacity-data-pop {
    background-color: rgba(0, 0, 0, .4);
    border: none;

    .popper__arrow:after {
        border-top-color: rgba(0, 0, 0, .4) !important;
    }

    .capacity-data-bd {
        border-radius: 4px;
        width: 229px;

        .capacity-data-bd-hd {
            display: flex;
            justify-content: space-between;
            color: #fff;
            align-items: center;
            height: 40px;

            .hd-button {
                border-radius: 20px;
                width: 50px;
                height: 20px;
                padding: 0;
                font-size: 12px;
                color: #333;
            }
        }

        .icon-btn {
            padding: 1px;
            font-size: 14px;
            background-color: #F1F1F1;
            border-radius: 500px;
        }

        .icon-bukejian {
            color: #909399;
        }

        .capacity-data-st {
            display: flex;
            margin-top: 10px;
            background-color: #fff;
            border-radius: 2px;
            align-items: center;
        }

        .capacity-data-bt {
            flex-direction: column;
            font-size: 12px;
            overflow: hidden;

            .hd {
                border-radius: 2px;
                height: 30px;
                background-color: #F1F1F1;
                line-height: 30px;
                width: 100%;
            }

            .bd {
                display: flex;
                padding: 10px;

                & > div {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }

}

</style>
<template>

    <div class="capacity-data-panel">
        <el-popover placement="top-start"
                    popper-class="capacity-data-pop"
                    trigger="click">
            <div class="capacity-data-bd">
                <div class="capacity-data-bd-hd">
                    <h3>运力数据</h3>
                    <el-button class="hd-button" icon="el-icon-refresh-right" size="mini" @click="handleRefresh">刷新
                    </el-button>
                </div>
                <div class="capacity-data-st" v-if="userInfo.userType!=='1'">
                    <select-city :ids="cityIds"
                                 placeholder="城市"
                                 :noSuffix="false"
                                 :immediate="true"
                                 :defaultCode="defaultCode"
                                 ref="selectCity"
                                 @update:city="handleSelectCity"
                                 size="mini"></select-city>
                    <supplier-select-width-city :cityId="form.cityId"
                                                size="mini"
                                                ref="supplierSelectWidthCity"
                                                v-if="!operatorId"
                                                v-model="form.supplierId">
                    </supplier-select-width-city>
                    <supplier-select :operatorId="operatorId"
                                     ref="supplierSelectWidthCity"
                                     v-if="operatorId"
                                     v-model="form.supplierId">
                    </supplier-select>
                </div>
                <div class="capacity-data-st capacity-data-bt">
                    <p class="hd">当前范围司机总 数：{{ list.length }}</p>
                    <div class="bd">
                        <div>
                            <p style="line-height: 1em;margin-bottom: 10px">
                                在线
                                <i class="icon icon-btn" :class="model.onlineShow?'icon-kejian':'icon-bukejian'"
                                   @click="handleModelChange('onlineShow')"></i>
                            </p>
                            <p class="color-1989FA num">{{ online.length }}</p>
                            <p style="margin-top: 10px">
                                <el-select @change="handleModelChange('onlineStatus',1)"
                                           v-model="model.onlineStatus"
                                           size="mini" style="font-size: 12px">
                                    <el-option label="全部订单" :value="0"></el-option>
                                    <el-option label="待出行" :value="1"></el-option>
                                    <el-option label="进行中" :value="2"></el-option>
                                    <el-option label="已完成" :value="3"></el-option>
                                </el-select>
                            </p>
                        </div>
                        <div>
                            <p style="line-height: 1em;margin-bottom: 10px">
                                离线
                                <i class="icon icon-btn" :class="model.offlineShow?'icon-kejian':'icon-bukejian'"
                                   @click="handleModelChange('offlineShow')"></i>
                            </p>
                            <p class="color-FF3B30 num">{{ list.length - online.length }}</p>
                            <p style="margin-top: 10px">
                                <el-select @change="handleModelChange('offlineStatus',1)"
                                           v-model="model.offlineStatus"
                                           size="mini" style="font-size: 12px">
                                    <el-option label="全部订单" :value="0"></el-option>
                                    <el-option label="待出行" :value="1"></el-option>
                                    <el-option label="进行中" :value="2"></el-option>
                                    <el-option label="已完成" :value="3"></el-option>
                                </el-select>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <el-button class="reference_button" type="primary" plain slot="reference">运力数据
            </el-button>
        </el-popover>
    </div>

</template>

<script type="text/ecmascript-6">
import SelectCity from '@/components/selectCity/'
import SupplierSelectWidthCity from '@/www/components/supplier-select-width-city/'
import SupplierSelect from '@/www/components/supplier-select/'
import {getOperatorServerCityByOperatorId} from '@/urls/'

export default {
    //定义模版数据
    data() {
        return {
            cityIds: null,
            operatorId: '',
            online: [],
            form: {
                cityId: '',
                supplierId: ''
            },
            model: {
                onlineShow: true,
                offlineShow: false,
                onlineStatus: 0,
                offlineStatus: 0,
                city: ''
            }
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        },
        defaultCode() {
            return this.userInfo.userType === '2' ? (this.cityIds ? this.cityIds[0] : null) : '3101'
        }
    },
    components: {
        SelectCity,
        SupplierSelectWidthCity,
        SupplierSelect
    },
    //主件被加载完成
    mounted() {
        this.init();
    },
    //定义事件方法
    methods: {
        async init() {
            //当前用户为运营商
            if (this.userInfo.userType === '2') {
                this.operatorId = this.userInfo.refId;
                this.cityIds = [];
                const ret = await getOperatorServerCityByOperatorId(this.userInfo.refId);
                if (ret.success && ret.data) {
                    this.cityIds = ret.data;
                }
            }
            if (this.userInfo.userType === '1')
                this.supplierId = this.form.supplierId = this.userInfo.refId
        },
        handleSelectCity(val) {
            this.form.cityId = val.shortCode
            this.model.city = val.city
            this.$emit('modelChange', this.model)
        },
        handleModelChange(key, type) {
            if (type !== 1)
                this.model[key] = !this.model[key];
            this.$emit('modelChange', this.model)
        },
        handleRefresh() {
            this.$emit('change', {...this.form})
        },
        blur() {
            this.$refs.selectCity && this.$refs.selectCity.blur();
            this.$refs.supplierSelectWidthCity && this.$refs.supplierSelectWidthCity.blur();
        }
    },
    //监听模版变量
    watch: {
        form: {
            handler: function (n) {
                this.$emit('change', {...n})
            },
            deep: true,
        },
        list() {
            this.online = this.list.filter(({online}) => online)
        }
    }

}
</script>
