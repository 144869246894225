<style lang="scss">
</style>
<template>
    <el-select class="resource-supplier"
               ref="select"
               filterable
               clearable
               :size="size"
               style="width: 100%"
               :disabled="disabled"
               @change="handleChange"
               v-model="form.id"
               placeholder="供应商">
        <el-option
            v-for="item in supplierList"
            :key="item.id"
            :label="item.supplierName"
            :value="item.id"
        >
        </el-option>
    </el-select>

</template>

<script type="text/ecmascript-6">
import {getSupplierByCityId} from '@/urls/'

export default {
    //定义模版数据
    data() {
        return {
            form: {
                id: null
            },
            supplierList: []
        }
    },
    props: {
        cityId: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: 'small'
        }
    },
    //主件被加载完成
    mounted() {
        this.getSupplier();
    },
    //定义事件方法
    methods: {
        async getSupplier() {
            let form = {cityId: this.cityId};
            if (!form.cityId)
                delete form.cityId
            let ret = await getSupplierByCityId(form);
            let list = [];
            this.handleChange(null)
            if (ret.success && ret.data)
                list = ret.data;
            this.supplierList = list;
            return true;
        },
        handleChange(val) {
            this.$emit('input', val);
            this.$emit('change', this.supplierList.find((item) => item.id === val) || {});
        },
        blur() {
            this.$refs.select.blur();
        }
    },
    //监听模版变量
    watch: {
        cityId() {
            //根据运营商联动
            this.getSupplier();
        },
        value() {
            this.form.id = this.value;
        }
    }

}
</script>
