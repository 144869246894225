<style lang="scss" scoped>
.container {
    position: relative;
}
</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont">
            <div class="container">
                <v-amap :terminals="driverList" :model="model" @click.native="handleMapClick"/>
                <capacity-data-panel @change="handleChange"
                                     ref="capacityDataPanel"
                                     @modelChange="handleModelChange"
                                     :list="driverList">
                </capacity-data-panel>
            </div>
        </div>
    </el-main>
</template>

<script type="text/ecmascript-6">
import Amap from './amap/index.vue'
import CapacityDataPanel from './capacity-data-panel/index'
import {selectDriverInfoByCityInfo} from '@/www/urls/trackUrls'

export default {
    name: 'capacityMonitor',
    //定义模版数据
    data() {
        return {
            driverList: [],
            model: {
                onlineShow: true,
                offlineShow: false,
                onlineStatus: 0,
                offlineStatus: 0,
            }
        }
    },
    components: {
        CapacityDataPanel,
        VAmap: Amap
    },
    deactivated() {
        clearInterval(this.timer);
        this.timer = null;
    },
    destroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
    //定义事件方法
    methods: {
        async handleChange(form = {}, loading = true) {
            loading && this.loadingShow();
            if (!form.cityId)
                delete form.cityId
            if (!form.supplierId) {
                delete form.supplierId;
            } else
                delete form.cityId
            const ret = await selectDriverInfoByCityInfo(form)
            loading && this.loadingHide();
            if (ret.success && ret.data) {
                this.driverList = ret.data.filter(({carNumberPlate}) => carNumberPlate).map((item) => ({
                    ...item,
                    online: +new Date() - item.locationTime < 5 * 60 * 1000
                }))
            } else
                this.$message.error(ret.errors[0].message)
            this.form = form;
            !this.timer && this.refreshTimer();
        },
        handleModelChange(model) {
            this.model = {...model};
        },
        refreshTimer() {
            this.timer = setInterval(() => {
                this.handleChange(this.form, false)
            }, 30000)
        },
        handleMapClick() {
            this.$refs.capacityDataPanel.blur();
        }
    }
}
</script>
