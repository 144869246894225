<style lang="scss" scoped>
.container {
    position: relative;
}

#mapContainer {
    width: 100%;
    height: calc(100vh - 132px);
}

.map-controls {
    position: absolute;
    right: 20px;
    bottom: 60px;
    width: 35px;
    text-align: center;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid rgba(229, 229, 229, 1);
    cursor: pointer;

    & > button {
        position: relative;
        display: block;
        width: 100%;
        margin-left: 0;
        border: none;
        border-radius: 0;
        padding: 0;
        height: 35px;
        line-height: 35px;
        font-size: 22px;
        font-weight: 400;

        &:after {
            position: absolute;
            content: " ";
            background-color: #F1F1F1;
            height: 1px;
            bottom: 0;
            left: 4px;
            right: 4px;
        }
    }
}
</style>
<style>
div[class="amap-layers"]:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(255, 255, 255, .6);
}
</style>
<template>
    <div class="container">
        <div id="mapContainer" @click="handleContainerClick"></div>
        <div class="map-controls">
            <el-button @click="handleControlClick(1)">
                +
            </el-button>
            <el-button @click="handleControlClick(-1)">
                -
            </el-button>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import config from '@/config/'
import {loadScrip} from '@/common/js/Utils'
import {orderStatusList} from '@/data/'

const images = [
    //在线
    require('../../../assets/images/map/mark1.png'),
    require('../../../assets/images/map/mark2.png'),
    //离线
    require('../../../assets/images/map/mark3.png'),
    require('../../../assets/images/map/mark4.png'),
    //超速
    require('../../../assets/images/map/mark5.png'),
    require('../../../assets/images/map/mark6.png'),
    //进行中
    require('../../../assets/images/map/mark7.png'),
    require('../../../assets/images/map/mark8.png'),
]

export default {
    //定义模版数据
    data() {
        return {
            city: null
        }
    },
    props: {
        terminals: {
            type: Array,
            default: () => []
        },
        model: {
            type: Object,
            default: () => ({})
        }
    },
    //主件被加载完成
    mounted() {
        this.init();
    },
    //定义事件方法
    methods: {
        async init() {
            if (!window.AMap) {
                const url = `${config.amap_url}&&plugin=AMap.Geocoder&callback=onMapLoad`;
                await loadScrip(url, true);
            }
            this.map = new window.AMap.Map('mapContainer', {
                resizeEnable: true,
                center: [121.473658, 31.230378],
                zoom: 11
            });
            this.map.setCity(this.city)
            this.icon0 = this.createIcon(0);
            this.icon1 = this.createIcon(1);
            this.icon2 = this.createIcon(2, 48, 56);
            this.icon3 = this.createIcon(3, 48, 56);
            this.icon4 = this.createIcon(4);
            this.icon5 = this.createIcon(5, 48, 56);
            this.icon6 = this.createIcon(6);
            this.icon7 = this.createIcon(7, 48, 56);
            this.createLabelsLayer();
            this.createHeatMap();
            this.addMarkers();
        },
        //点击地图工具缩放地图
        handleControlClick(num) {
            this.map.setZoom(this.map.getZoom() + num)
        },
        //点击地图
        handleContainerClick() {
            !this.iconClick && this.resetActiveMarker();
        },
        //创建多个终端标记
        addMarkers() {
            if (!this.map)
                return;
            this.currentActiveMarker = null;
            this.infoWindow && this.map.clearInfoWindow();
            this.markers && this.labelsLayer.remove(this.markers);
            const terminals = this.terminals.filter((tm) => this.getVisibleMarker(tm))
            this.markers = terminals.map((tm, idx) => this.crateMarker(tm, idx))
            this.labelsLayer.add(this.markers)
            this.map.add(this.labelsLayer);
            this.setHeatMapData(terminals);
        },
        //创建LabelsLayer()
        createLabelsLayer() {
            this.labelsLayer = new window.AMap.LabelsLayer({
                zooms: [3, 20],
                zIndex: 1000,
                // 该层内标注是否避让
                collision: true
            });
        },
        //创建热力图
        createHeatMap() {
            this.map.plugin(["AMap.Heatmap"], () => {
                this.heatmap = new window.AMap.Heatmap(this.map, {
                    radius: 25, //给定半径
                    opacity: [0, 0.8]
                    /*,
                    gradient:{
                        0.5: 'blue',
                        0.65: 'rgb(117,211,248)',
                        0.7: 'rgb(0, 255, 0)',
                        0.9: '#ffea00',
                        1.0: 'red'
                    }
                     */
                });
            });
        },
        //设置热力图数据
        setHeatMapData(terminals) {
            this.t = +new Date();
            let heatmapData = [...terminals],
                max = 0,
                points = [],
                k = 0.05;
            if (heatmapData.length > 0) {
                points = [
                    {
                        lat: heatmapData[0].lat,
                        lng: heatmapData[0].lon,
                        count: 1
                    }
                ];
                for (let i = 1; i < heatmapData.length; i++) {
                    const index = points.findIndex(({
                                                        lng,
                                                        lat
                                                    }) => lng - heatmapData[i].lng <= k && lat - heatmapData[i].lat)
                    if (index > -1)
                        points[index].count++;
                    else
                        points.push({
                            lat: heatmapData[i].lat,
                            lng: heatmapData[i].lon,
                            count: 1
                        })
                }
            }
            this.heatmap.setDataSet({
                data: points,
                max
            });
        },
        //判断当前marker是否应该显示
        getVisibleMarker(tm) {
            let visible = false;
            const {onlineShow, offlineShow, onlineStatus, offlineStatus} = this.model;
            const {online, orderNo, orderStatus} = tm;
            if ((online && onlineShow && (onlineStatus === 0 ||
                    (onlineStatus === 1 && orderNo && orderStatus === 41) ||
                    (onlineStatus === 2 && orderNo && orderStatus === 51) ||
                    (onlineStatus === 3 && (!orderNo || ![41, 51].includes(orderStatus)))))
                || (!online && offlineShow && (offlineStatus === 0 ||
                    (onlineStatus === 1 && orderNo && orderStatus === 41) ||
                    (offlineStatus === 2 && orderNo && orderStatus === 51) ||
                    (offlineStatus === 3 && (!orderNo || ![41, 51].includes(orderStatus))))))
                visible = true;
            return visible;
        },
        //创建标记
        crateMarker(tm, idx) {
            let {lon, lat, online, carNumberPlate, orderStatus, speed} = tm;
            let icon = online ? this.icon0 : this.icon1;
            if ([42, 43, 51].includes(orderStatus))
                icon = this.icon6;
            if (speed * 3.6 > 110)
                icon = this.icon4;
            let offset = new window.AMap.Pixel(-14, -28);
            let position = new window.AMap.LngLat(lon, lat);
            let enlarge = 0;//图标是否放大
            if (this.currentActiveMarker && this.currentActiveMarker.name === carNumberPlate) {
                icon = online ? this.icon3 : this.icon2;
                if ([42, 43, 51].includes(orderStatus))
                    icon = this.icon7;
                if (speed * 3.6 > 110)
                    icon = this.icon5;
                offset = new window.AMap.Pixel(-24, -56);
                this.createInfoWindow(tm, position);
                enlarge = 100;
            }
            const markerOptions = {
                name: carNumberPlate,
                position,
                zIndex: (online ? 10000 + idx : idx) + enlarge,
                rank: (online ? 100000 + idx : idx) + enlarge,
                // 将第一步创建的 icon 对象传给 icon 属性
                icon,
                // 将第二步创建的 text 对象传给 text 属性
                text: carNumberPlate,
                offset,
                extData: {
                    ...tm,
                    idx,
                },
            }
            const marker = new window.AMap.LabelMarker(markerOptions);
            window.AMap.event.addListener(marker, 'click', () => {
                this.resetActiveMarker();
                this.setMarkStatus(marker, markerOptions)
                this.iconClick = true;
                setTimeout(() => {
                    this.iconClick = false
                }, 200)
            })
            return marker;
        },
        //重置已经打开的Marker
        resetActiveMarker() {
            this.map.clearInfoWindow();
            if (this.currentActiveMarker)
                this.setMarkStatus(this.currentActiveMarker, this.currentActiveMarkerOptions)
        },
        //当点击图标和地图
        setMarkStatus(marker, data) {
            const {extData} = data;
            let offset = new window.AMap.Pixel(-24, -56);
            let icon = extData.online ? this.icon3 : this.icon2;
            if ([42, 43, 51].includes(extData.orderStatus))
                icon = this.icon7;
            if (extData.speed * 3.6 > 110)
                icon = this.icon5;
            if (extData.show) {
                offset = new window.AMap.Pixel(-14, -28);
                icon = extData.online ? this.icon0 : this.icon1;
                if ([42, 43, 51].includes(extData.orderStatus))
                    icon = this.icon6;
                if (extData.speed * 3.6 > 110)
                    icon = this.icon4;
                this.currentActiveMarker = null;
                this.currentActiveMarkerOptions = null;
            } else {
                this.createInfoWindow(extData, data.position);
                this.currentActiveMarker = marker;
                this.currentActiveMarkerOptions = data;
            }
            data.extData.show = !extData.show;
            marker.offset = offset;
            marker.setIcon(icon)
        },
        //创建标记图标
        createIcon(num, w = 32, h = 32) {
            return {
                // 图标类型，现阶段只支持 image 类型
                type: 'image',
                // 图片 url
                image: images[num],
                // 图片尺寸
                size: [w, h],
                // 图片相对 position 的锚点，默认为 bottom-center
                anchor: 'center',
            };
        },
        //创建信息弹窗
        createInfoWindow(data, position) {
            this.infoWindow = new window.AMap.InfoWindow({
                isCustom: true,  //使用自定义窗体
                content: this.createInfoWindowContent(data),
                offset: new window.AMap.Pixel(0, -40)
            });
            this.infoWindow.open(this.map, position);
            return this.infoWindow;
        },
        createInfoWindowContent(data) {
            const {lat, lon, online, carNumberPlate, driverName, orderNo, orderStatus, speed} = data;
            const status = orderStatusList.find(({value}) => value == orderStatus) || {}
            return `<div style="padding:0 15px;background-color: #fff;border-radius: 4px;font-size: 12px;position: relative;box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.10);">
<div style="padding: 13px 0">
<span style="color: ${online ? '#1989FA' : '#FF3B30'};font-size: 16px;font-weight: 600">${carNumberPlate}</span>
<span style="border: 1px solid ${online ? '#1989FA' : '#FF3B30'};background-color: ${online ? '#E6F7FF' : '#FFF1F0'};color: ${online ? '#1989FA' : '#FF3B30'};padding: 2px 5px">${online ? '在线' : '离线'}</span>
</div>
<div>
<p>在驾司机：${driverName}</p>
<p>当前时速：${speed > 0 ? Number(speed * 3.6 || 0).toFixed(2) + 'km/h' : '静止'}</p>
<p>当前经纬度：${Number(lon || 0).toFixed(6)},${Number(lat || 0).toFixed(6)}</p>
</div>
<div style="display: flex;justify-content: space-between;border-top: 1px solid #f8f8f8;padding: 13px 0;">
<span>订单状态：${orderNo ? status.name : '暂无订单'}</span>
<a href="#/userCarList?orderNo=${orderNo}" style="color: #1989FA;display:${orderNo ? 'block' : 'none'}">
查看
<i class="icon icon-small-arrow-right" style="color: #999;font-size: 12px"></i>
</a>
</div>
<i class="icon icon-triangle-down" style="color:#fff;font-size:38px;position: absolute;bottom: -23px;left: 50%;margin-left: -19px;"></i>
</div>`
        }
    },
    watch: {
        terminals() {
            this.addMarkers()
        },
        model: {
            handler: function (n) {
                setTimeout(() => {
                    this.addMarkers();
                })
                if (n.city === this.city)
                    return
                this.city = n.city;
                this.map && this.map.setCity(n.city)
            },
            deep: true
        }
    }
}
</script>
